import { Box, Flex } from 'grid-styled'
import * as React from 'react'
import Layout from '../components/Layout'
import { Helmet } from 'react-helmet'

import CTA from '../components/CTA'
import Header from '../components/Header'
import FAQs from '../components/FAQs'
import Img from 'gatsby-image'


export default ({ data }) => (
  <Layout>
    <Helmet>
      <title>FAQ’s - Plastic Surgery Sydney</title>
      <meta
        name="description"
        content="Plastic Surgery frequently asked questions (FAQs) - Dr Graham Sellars is a plastic and cosmetic surgeon in Sydney, NSW providing breast implants, breast enlargement, rhinoplasty nose jobs and eyelid surgery."
      />
    </Helmet>
    <Header>
      <h1>Frequently asked questions</h1>
      <Img
        fluid={data.teamSmall.childImageSharp.fluid}
        style={{ borderRadius: 8, marginBottom: 5, marginTop: 60 }}
      />
    </Header>
    <FAQs />
    <CTA
      title="Having an issue?"
      subtitle="We’re here to help you."
      button="Contact Us"
    />
  </Layout>
)

export const query = graphql`
  query FAQQuery {
    teamSmall: file(relativePath: { eq: "flower.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
