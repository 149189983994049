import * as React from 'react'
import styled, { css } from 'styled-components'
import { xPad, yPad, yMargin } from '../utils/theme'
import Accordion from './Accordion'
import { darken } from 'polished'

interface IFAQContainerProps {
  flushTop: boolean
}

const FAQContainer = styled<IFAQContainerProps, any>('div')`
  background-color: #fdf5f9;
  ${yMargin};
  margin-bottom: 0 !important;

  ${props =>
    props.flushTop &&
    css`
      margin-top: 0 !important;
    `};
`

const FAQs = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin: 0 auto;
  max-width: 1000px;

  ${yPad};
  ${xPad};

  h3 {
    color: ${props => darken(0.4, props.theme.colors.primary)};
    &:not(:first-child) {
      padding-top: 2em;
    }
  }
`

interface IFAQ {
  title?: string
  answer?: string
  question?: string
  isHtml?: boolea
}

interface IFAQProps {
  flushTop?: boolean
  title: string
  data: IFAQ[]
}

const data = [
  {
    question: 'Are referrals from my GP necessary?',
    answer:
      'From 1st July 2023 it is a legal requirement to get a GP referral prior to consultation with a plastic surgeon, even if you are coming to discuss a cosmetic procedure. The referral forms part of your treatment plan including medical history, medications, allergies etc. A referral will also be required should there be a Medicare rebate. For non-surgical consultations such as Treatments for Wrinkles and Volume Loss, a GP referral is not necessary.'
  },
  {
    question:
      'Are there risks involved with having Plastic / Cosmetic surgery?',
    answer:
      'As with any surgery there are risks involved. It is important that you discuss the risks and possible complications with your Surgeon and/or GP before you go ahead with any procedure. We recommend you make an informed decision about whether to have your chosen procedure.'
  },
  {
    question: 'Will I need follow-up consultations with the Surgeon?',
    answer:
      'Generally yes, you will have follow-up consultation(s) but it depends on what procedure(s) you have. Dr Sellars will follow your progress and if at any time you have any issues or queries please contact the surgery.'
  },
  {
    question: 'Will I need to be hospitalised?',
    answer:
      'Depending on the type of surgery, age of patient and general medical condition of the patient, surgery may be performed under local anaesthetic in the consulting rooms, or under sedation or general anaesthetic in hospital. Dr Sellars operates at The Sydney Adventist Hospital in Wahroonga and The San Day Surgery Hornsby. Details of hospital admissions will be determined at time of consultation. At our San Clinic rooms we have two minor procedure theatres that are used for minor local anaesthetic procedures such as mole removal/skin cancer excision, and also non-surgical treatments such as IPL/Laser.'
  },
  {
    question: 'How long will I need to be off work?',
    answer:
      'These questions will be answered at time of consultation and will depend of type of surgery and medical condition of patient'
  },
  {
    question: 'How can I find out more on the internet?',
    answer:
      'Please use the links on our helpful links page to search for more information.'
  },
  {
    question: 'Should I seek a second opinion?',
    answer:
      'This is entirely up to you; however, if you do not feel comfortable with your Surgeon, or their suggestions, or experience, by all means seek other medical opinions.'
  },
  {
    question:
      'Is there a difference between a “Cosmetic Surgeon” and a “Plastic Surgeon”?',
    isHtml: true,
    answer: `<p>Always check the credentials of your Surgeon, as not all Doctors who are entitled to practice Cosmetic Surgery are qualified as Plastic Surgeons. Many Medical Practitioners (such as GP’s, Dermatologists, General Surgeons) call themselves a Cosmetic Surgeon, if they happen to practice this type of work. It is important to check training and qualifications of your Surgeon. Members of the Australian Society of Plastic Surgeons (ASPS) are highly trained Specialists, who have spent at least eight (8) to ten (10) years in post graduate surgical training. It is highly recommended that you only have surgery by a Plastic Surgeon.</p>
      <p>It is of paramount importance for patients to research and be well informed that their surgeon has the appropriate qualifications to undertake their surgery. Even more important is the safety and environment in which they will perform the procedure, and that they will be able to treat any complication should it so arise.</p>
<p>There are no rules or regulations in Australia that dictate which doctors can perform which surgeries. Because cosmetic surgery often attracts no funding from Medicare or Private Insurance, the industry remains unregulated and any doctor with little or no training can perform cosmetic surgery. </p>
<p>Plastic surgeons do perform cosmetic surgery but and will always call themselves “Plastic Surgeons”. Cosmetic surgeons perform cosmetic surgery but cannot call themselves “Plastic Surgeons”. This title is what defines the qualification and training undertaken.</p>
<p>There are some key differences between Plastic & Cosmetic Surgeons. Read below to find out more</p>
`
  },
  {
    question: 'Training and Qualifications',
    isHtml: true,
    answer: `
      <p>Dr Sellars is a fully trained Plastic, Reconstructive & Cosmetic Surgeon with the qualification of Fellowship of the Royal Australasian College of Surgeons (FRACS) in Plastic Surgery.</p>
      <p>Royal Australasian College of Surgeons is the only body in Australia accredited to train surgeons in this country in any one of the nine surgical specialties. This means that Plastic Surgeons receive the same rigorous training as a cardiac surgeon, neuro surgeon, orthopaedic surgeon, etc. This includes an extremely competitive entry requirement, and once selected for training surgeons train for 5 years in their specialty. Subsequently they have to pass one of the most rigorous fellowship exams in the world, to have the qualification “FRACS”.</p>
      <p>There are various colleges of ‘Cosmetic Surgery’ in Australia – unfortunately in Australia there are no parameters around what is required for colleges to be set up. Therefore membership of these colleges does not guarantee a certain level or quality of training. The duration of training from these colleges is often short, and these colleges are not recognised by AHPRA, Medicare, Health Funds or Private hospitals. The only training recognised by the Australian Medical Council and all medical institutions in Australia is that provided by RACS, which provides training to all surgeons in this country.</p>
      `
  },
  {
    question: 'What does FRACS mean?',
    isHtml: true,
    answer: `<p>FRACS stands for “Fellow of the Royal Australasian College of Surgeons”
      If your surgeon has the letters FRACS after their name this indicates they have undergone the rigorous selection process, training and examination process of the College of Surgeons.</p>
      <p>The letters FRACS (Plast) indicates that your surgeon has undergone the Plastic and Reconstructive surgery training under the College of Surgeons, the only training program dedicated to the teaching and training of plastic surgery in this country.</p>
      <p>Your safeguard is to look for a fully qualified Plastic Surgeon as reflected by the letters FRACS (Plast) next to your doctor’s name and check that they are affiliated with the Australian Society of Plastic Surgeons (ASPS). These titles and memberships are only available to fully qualified Plastic Surgeons. </p>`
  },
  {
    question: 'What does ASPS and ASAPS mean?',
    isHtml: true,
    answer: `<p>ASPS  stands for ‘Australian Society of Plastic Surgeons’ – This is the peak body for Specialist Plastic Surgeons (both reconstructive and cosmetic) in this country.</p>
      <p>ASAPS stands for ‘Australasian Society of Aesthetic Plastic Surgeons’ – this is the peak body for cosmetic plastic surgery in Australia, comprising of Plastic Surgeons who have a dedicated interest in the field of cosmetic surgery.</p>
      <p>Cosmetic Surgeons cannot be granted affiliation with these two organisations.</p>
      <p>For more information visit:</p>
      <p>ASPS – plasticsurgery.org.au; ASAPS – aestheticplasticsurgeons.org.au</p>
      `
  }
]

export default ({ title, faqs, ...rest }: IFAQProps) => {
  const info: IFAQ[] = faqs || data
  return (
    <FAQContainer {...rest}>
      <FAQs>
        <h3>{title}</h3>
        {info.map(faq => {
          if (faq.answer && faq.question) {
            return (
              <Accordion
                answer={faq.answer}
                key={faq.question}
                question={faq.question}
                isHtml={faq.isHtml}
              />
            )
          }
        })}
      </FAQs>
    </FAQContainer>
  )
}
