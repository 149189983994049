import styled, { css } from 'styled-components'
import { transparentize } from 'polished'

export default styled('header')`
  padding-top: 7rem;
  text-align: center;
  padding-left: 3rem;
  padding-right: 3rem;
  max-width: 1000px;
  margin: 0 auto;

  h3 {
    background: #fff;
    box-shadow: 0 8px 18px
      ${props => transparentize(0.9, props.theme.colors.dark)};
    border-radius: 4px;
    padding: 2rem;
  }

  /* img {
    position: absolute;
    top: 0;
    left: -50%;
    min-width: 200vw;
    z-index: -1;

    @media (max-width: 939px) {
      min-width: 300vw;
      left: -75%;
    }

    @media (max-width: 639px) {
      min-width: 600vw;
    }
  } */

  @media (min-width: 600px) {
    padding-top: 9rem;
  }
  @media (min-width: 940px) {
    padding-top: 10rem;
  }
  @media (min-width: 1180px) {
    padding-top: 13rem;
  }
`
